.flex-vertical {
	display: flex;
	flex-direction: column;
}

.parent-container {
	display: flex;
	flex-wrap: wrap;
}

.App {
	max-width: 600px;
	margin-top: 100px;
}

.submit {
	padding: 20px;
	margin: 30px;
}

body {
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
}

.section {
	margin: 0 20px;
}

form {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.field {
	margin: 5px 0 20px;
	padding: 5px 10px;
}

.errorValidation {
	color: red;
}

@media only screen and (max-width: 490px) {
	.parent-container {
		flex-direction: column;
	}
}
